import React from 'react';
import { Box, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { createAmplitudeEventWithDevice } from '../../../components/amplitude';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { StepContainer, StepContent, StepContentTitle } from './styles';
import { ToggleUI } from '../../../components/common';
import { useSelector } from 'react-redux';

export const OnboardingStep = ({ onboardingStepData }) => {
  const idCountry =
    useSelector((state) => state.tienda.Country_idCountry) || 'ARG';

  const history = useHistory();

  const handleClickCard = () => {
    if (onboardingStepData.amplitude_event) {
      createAmplitudeEventWithDevice(onboardingStepData.amplitude_event);
      if (idCountry === 'ARG' && onboardingStepData.link_to === '/builder') {
        history.push('/selecciona-plantilla');
        localStorage.setItem('come-from-step-guide', true);
      } else {
        history.push(onboardingStepData.link_to);
      }
    }
  };

  const handleOnKeyPress = ({ code }) => {
    code === 'Enter' && handleClickCard();
  };

  const Icon = onboardingStepData.image;

  const stepCompleted = onboardingStepData.completed;

  return (
    <StepContainer
      tabIndex={stepCompleted ? -1 : 0}
      role="button"
      onClick={!stepCompleted ? handleClickCard : null}
      onKeyDown={!stepCompleted ? handleOnKeyPress : null}
      stepState={stepCompleted}
      cursorPointer={onboardingStepData.link_to && !stepCompleted}
    >
      <StepContent>
        <Box
          sx={{
            display: 'flex',
            alignItems: onboardingStepData.link_to ? 'flex-start' : 'center',
          }}
        >
          <Icon htmlColor={stepCompleted ? '#53B755' : '#383838'} />
          <Box ml={2}>
            <StepContentTitle component="h3">
              {onboardingStepData.title[stepCompleted ? 'success' : 'default']}
            </StepContentTitle>
            <ToggleUI show={!!onboardingStepData.subtitle && !stepCompleted}>
              <Typography
                component="p"
                sx={{
                  fontSize: '0.875rem',
                  fontWeight: '400',
                  marginTop: '6px',
                }}
              >
                {onboardingStepData.subtitle}
              </Typography>
            </ToggleUI>
          </Box>
        </Box>

        <Box>{stepCompleted ? <CheckCircleIcon /> : <ChevronRightIcon />}</Box>
      </StepContent>
    </StepContainer>
  );
};
